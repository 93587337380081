@import 'styles/breakpoints';

.section {
  padding: 0;

  &.rounded {
    border-radius: var(--radius-sm);
  }
}

.featured-article {
  border: 1px solid var(--neutral-300);
  border-radius: var(--radius-sm);
  overflow: hidden;

  @include until($widthDesktop) {
    padding: var(--spacing-500);
  }

  &.borderless {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  a.article {
    background-color: var(--bg-default);
    display: grid;
    grid-template-columns: 50% 50%;
    color: var(--text-default);
    align-items: center;
    text-decoration: none;

    @include until($widthTablet) {
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing-500);
    }

    @include until($widthMobile) {
      grid-template-columns: 1fr;
      padding: 0;
    }

    &:hover {
      .thumbnail {
        transform: scale(1.05);
      }
    }
  }

  .thumbnail-wrapper {
    width: 100%;
    position: relative;
    max-height: 400px;
    height: 100%;
    overflow: hidden;

    @include until($widthDesktop) {
      max-height: 360px;
    }

    @include until($widthTablet) {
      max-height: 300px;
    }

    @include until($widthMobile) {
      height: 200px;
    }

    .thumbnail {
      width: 100%;
      object-fit: cover;

      @media (prefers-reduced-motion: no-preference) {
        transition: transform var(--transition-speed) ease-in-out;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
    padding-left: var(--spacing-600);
    padding-right: var(--spacing-600);

    @include until($widthMobile) {
      padding: 0;
    }

    .description {
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
      margin-top: 0;
      margin-bottom: auto;
    }
  }

  &.content-block {
    a.article {
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing-600);

      @include until($widthTablet) {
        grid-template-columns: 1fr;
        gap: var(--spacing-500);
      }

      @include until($widthMobile) {
        gap: 0;
      }
    }

    @include until($widthMobile) {
      grid-template-columns: 1fr;
    }

    .thumbnail-wrapper {
      max-height: unset;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @include until($widthTablet) {
        height: 240px;
      }

      @include until($widthMobile) {
        border-bottom-left-radius: 0;
      }
    }

    .content {
      padding: 40px 40px 40px 0;

      @include until($widthTablet) {
        padding: 0 40px 40px;
      }

      @include until($widthMobile) {
        padding: 20px;
      }

      h2 {
        @include until($widthMobile) {
          font-size: var(--heading-sm-font-size);
        }
      }
    }
  }
}
