@import 'styles/breakpoints';

.categories {
  padding-bottom: 0;

  @include until($widthTablet) {
    :global(.container) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  h2 {
    @include until($widthTablet) {
      padding-left: var(--spacing-400);
      padding-right: var(--spacing-400);
    }
  }

  .categories-nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: var(--spacing-500);
    margin-top: var(--spacing-500);
    margin-bottom: var(--spacing-500);

    @include until($widthTablet) {
      gap: var(--spacing-400);
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      padding-left: var(--spacing-400);
      padding-right: var(--spacing-400);
      margin-bottom: var(--spacing-500);
    }

    @include until($widthMobile) {
      margin-bottom: var(--spacing-200);
    }
  }
}

.articles {
  padding-top: 0;
  margin-bottom: var(--spacing-500);
}
