@import 'styles/breakpoints';

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  min-width: 200px;
  border-radius: var(--radius-md);
  background-color: var(--bg-layer);
  font-size: var(--text-lg-font-size);
  line-height: var(--text-lg-line-height);
  color: var(--text-default);
  font-weight: bold;
  cursor: pointer;

  &.active {
    border: 2px solid var(--border-action);
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  }

  &:hover {
    color: var(--text-primary-hover);
  }
}
