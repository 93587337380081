@import 'styles/breakpoints';

.tag {
  font-weight: 600;
  font-size: var(--text-sm-font-size);
  line-height: var(--text-sm-line-height);
  color: var(--text-primary);
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing-200);
  width: fit-content;

  &.is-small {
    font-size: var(--text-xxs-font-size);
    line-height: var(--text-xxs-line-height);
  }

  &.with-background {
    padding: var(--spacing-200) var(--spacing-400);
    font-size: var(--text-xxs-font-size);
    line-height: var(--text-xxs-line-height);
    letter-spacing: 0.12px;
    border-radius: var(--radius-md);
    background-color: var(--accent-brand-subdued);
  }

  &.brand {
    color: var(--text-primary);

    &.with-background {
      color: var(--on-brand-subdued);
      background-color: var(--accent-brand-subdued);
    }
  }

  &.yellow {
    color: var(--accent-yellow);

    &.with-background {
      color: var(--on-yellow-subdued);
      background-color: var(--accent-yellow-subdued);
    }
  }

  &.orange {
    color: var(--accent-orange);

    &.with-background {
      color: var(--on-orange-subdued);
      background-color: var(--accent-orange-subdued);
    }
  }

  &.purple {
    color: var(--accent-purple);

    &.with-background {
      color: var(--on-purple-subdued);
      background-color: var(--accent-purple-subdued);
    }
  }

  &.pink {
    color: var(--accent-pink);

    &.with-background {
      color: var(--on-pink-subdued);
      background-color: var(--accent-pink-subdued);
    }
  }

  &.light-blue {
    color: var(--accent-lightblue);

    &.with-background {
      color: var(--on-lightblue-subdued);
      background-color: var(--accent-lightblue-subdued);
    }
  }
}
