.img {
  max-width: 100%;
  max-height: 100%;

  &.fill {
    height: 100%;
    width: 100%;
  }

  &.mask-house {
    mask-image: url('../../../public/images/masks/house-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
