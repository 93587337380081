@import 'styles/breakpoints';

.article-card {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-sm);
  overflow: hidden;
  color: var(--text-default);
  background-color: var(--bg-layer);
  height: 100%;
  min-height: 360px;
  text-decoration: none;

  &:hover {
    .thumbnail {
      transform: scale(1.05);
    }
  }

  .thumbnail-wrapper {
    width: 100%;
    min-height: 180px;
    aspect-ratio: 2/1;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;

    @include from($widthDesktop) {
      aspect-ratio: 4/3;
    }

    .default-img-wrapper {
      display: block;
      height: 100%;
      overflow: hidden;
    }

    .thumbnail {
      object-fit: cover;
      height: 100%;
      width: 100%;
      @media (prefers-reduced-motion: no-preference) {
        transition: transform var(--transition-speed) ease-in-out;
      }
    }
  }

  .content {
    padding: var(--spacing-400);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
    height: 100%;
    flex-grow: 1;

    .tag {
      font-weight: bold;
    }

    .title {
      font-weight: 600;
      flex-grow: 1;

      svg {
        display: inline-block;
        vertical-align: middle;
        height: var(--text-sm-font-size);
      }
    }

    .author {
      color: var(--neutral-500);
      margin-top: auto;
      padding-top: var(--spacing-400);
    }
  }
}
