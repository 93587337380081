@import 'styles/breakpoints';

.author-container {
  display: flex;
  align-items: center;

  .avatar {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    margin-right: var(--spacing-400);
    overflow: hidden;
    object-fit: cover;
  }

  .date {
    font-size: var(--text-xs-font-size);
    line-height: var(--text-xs-line-height);
    text-transform: uppercase;
    color: var(--neutral-500);
  }
}
