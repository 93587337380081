@import 'styles/breakpoints';

.article-section {
  padding-top: var(--spacing-800);

  @include until($widthDesktop) {
    padding-top: var(--spacing-700);
  }

  @include until($widthMobile) {
    padding-top: 50px;
  }

  .card-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: var(--spacing-500);
    margin-top: var(--spacing-500);

    @include from($widthMobile) {
      gap:var(--spacing-600);
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }

  .button-group {
    margin: var(--spacing-500) auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-400);

    .show-more {
      display: block;

      @include until($widthMobile) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
