// Can't use CSS variables as media query values.
$widthMobile: 600px;
$widthTablet: 900px;
$widthDesktop: 1280px;
$widthDesktopLarge: 1600px;
$header-break-point: 1170px;

@mixin from($width) {
  @media (min-width: $width + 1) {
    // add 1px because when the until & from mixin are used together there is 1px uncovered
    @content;
  }
}

@mixin until($width) {
  @media (max-width: $width) {
    @content;
  }
}
